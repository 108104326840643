






































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: {}
})
export default class Results extends ListBase {
    columns = [
        { value: 'record', text: 'Expediente', type: 'text', sortable: false },
        { value: 'name', text: 'Titulo', type: 'text', sortable: false },
        { value: 'application_date', text: 'Fecha', type: 'text', sortable: false },
        { value: 'comments', text: 'Comentarios', type: 'text', sortable: false },
        { value: 'filename_request', text: 'Documento', type: 'text', sortable: false },
        { value: 'state_id', text: 'Estado', type: 'text', sortable: false },
        { value: 'filename_response', text: 'Respuesta', type: 'text', sortable: false }
    ];
    
    get loading() {
        return this.$store.state.trade.loading;
    }
    get list() {
        return this.$store.state.trade.list;
    }
    get user() {
        return this.$store.state.session.user;
    }

    async getpage() {
        let result = await this.$store.dispatch({
            type: 'trade/getAll',
            data: { lawyer_id: Util.abp.session.userId }
        });
    }

    async mounted() {
        await this.getpage();
    }
}